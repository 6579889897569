<template>
  <div class="pageBox has__nav__menu">
    <h1 class="textHide">お気に入り</h1>
    <p v-if="pageTotal" class="txtMenuBtn" @click="toggleModal(true)"><font-awesome-icon icon="trash-alt" /><span class="hide">お気に入りを消す</span></p>
    <h2 class="boxTitle">お気に入りアイテム</h2>
    <div id="itemList">
      <div v-if="isLoading" class="fullLoading">
        <loading :size="2" :icon="'spinner'" />
      </div>
      <div v-else v-for="data in getList" :key="data.page">
        <Itemlist v-bind:lists="data.items" />
        <loading v-if="data.loading" :size="2" :icon="'spinner'">
          <p class="loadTxt" v-if="'parseInt(data.page) + 1 < pageTotal'" :id="'page' + Number(parseInt(data.page) + 1)">{{data.next}}ページ目をロードしています</p>
        </loading>
      </div>
    </div>
    <h2 class="boxTitle">お気に入りブランド</h2>
    <div>
      <ul>
        <li v-for="(brand, index) in brandList" :key="index">
          <div class="flex__center__box">
            <BrandLink :brand_code="brand.code" :brand_name_en="brand.name_en" :brand_name_ja="brand.name_ja"/>
            <BrandBtn :brand_id="brand.id" />
          </div>
        </li>
      </ul>
    </div>
    <showAll />
    <modalAlert v-if="modalOn">
      <div class="modal-box__block__inner">
        <p class="modal__title">お気に入りアイテムを削除しますか？</p>
        <p class="notice">この操作は取り消し・復元ができません。</p>
      </div>
      <div class="modal-box__block__buttons">
        <div class="modal-box__block__buttons__btn cancel" @click="toggleModal(false)">
          キャンセル
        </div>
        <div class="modal-box__block__buttons__btn" @click="removeViewed">
          OK
        </div>
      </div>
    </modalAlert>
  </div>
</template>

<script>
import axios from 'axios'
import Itemlist from "@/components/block/Itemlist.vue";
import loading from "@/components/atom/loading.vue";
import {Mixin} from '@/mixins/common'
import showAll from "@/components/parts/showAll.vue";
import modalAlert from "@/components/parts/modalAlert.vue";
import BrandLink from "@/components/parts/BrandLink.vue";
import BrandBtn from "@/components/parts/BrandBtn.vue";
import { mapGetters, mapActions } from "vuex"


export default {
  data() {
    return {
      dataList: {},
      brandList: {},
      pageTotal: false,
      pageNumber: 1,
      isLoading: true,
      listLoading: false,
      modalOn: false,
    };
  },
  mixins:[Mixin],
  components: {
    Itemlist,
    loading,
    showAll,
    modalAlert,
    BrandLink,
    BrandBtn,
  },
  mounted () {
    this.initialize()
    this.setupPage()
  },
  computed: {
    ...mapGetters([
      'starBrands', 'starItems'
    ]),
    getList: function() {
      return this.dataList;
    },
    getTotal: function() {
      return this.dataList;
    },
  },
  methods: {
    ...mapActions([
      "clearStar"
    ]),
    toggleModal(valid){
      this.modalOn = valid
    },
    initialize(){
      this.dataList = {}
      this.pageNumber = null
      this.isLoading = true
      this.listLoading = false
      this.pageTotal = false
    },
    async setupPage(){
      await this.getData(this.pageNumber);
      this.getBrand()
    },
    async getData(){
      this.listLoading = true

      if(this.starItems.length > 0){
        let url = this.api + '/items/ids/?ids=' + this.starItems.join() + '&sort=no'
        console.log(url);

        axios.get(url)
        .then((res) => {
          this.dataList['0'] = {page: 1, next: null, items: res.data.list}
          this.dataList['0'].loading = false
          this.pageTotal = res.data.item_total
          this.isLoading = false
        })
        .catch(() =>{
          console.log('star items not found')
        })
      }else{
        this.isLoading = false
      }
    },
    getBrand(){
      if(this.starBrands.length > 0){
        let url = this.api + '/brands/lists?ids=' + this.starBrands.join()
        console.log(url);
        axios.get(url)
        .then((res) => {
          this.brandList = res.data
        })
        .catch(() =>{
          console.log('star brand not found')
        })
      }
    },
    removeViewed(){
      this.clearStar({list: 'Items'})
      this.initialize()
      this.setupPage()
      this.toggleModal(false)
    }
  }
}
</script>
